.numbers {
    &__number {
        border: 0.6rem solid $primary;
        width: 100%;
        margin-bottom: 1.2rem;

        @include md-up {
            width: calc(50% - 0.6rem);
            min-height: 16rem;
        }
        
        @include xl-up {
            width: calc(20% - 0.6rem);
            min-height: 20rem;
        }

        & h2 {
            font-size: 4rem;

            @include lg-up {
                font-size: 3.2rem;
            }
        }
    }
}
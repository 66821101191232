.history {
    &__wrapper {
        position: relative;

        &::after {
            content: '';
            position: absolute;
            width: 0.6rem;
            height: 100%;
            top: 0;
            background-color: $gray-600;
            z-index: -1;
            left: 0.4rem;
            
            @include lg-up {
                left: 50%;
                transform: translate(-50%, 0);
            }
        }

        &::before {
            content: '';
            position: absolute;
            top: 0;
            left: 0.4rem;
            width: 0.6rem;
            height: 0.6rem;
            z-index: 2;
            box-shadow: 
            0 -1.2rem 0 $gray-600,  /* First dot above */
            0 -2.4rem 0 $gray-600,  /* Second dot above */
            0 -3.6rem 0 $gray-600;  /* Second dot above */

            @include lg-up {
                left: 50%;
                transform: translate(-50%, 0);
            }
        }
    }

    &__item {
        width: calc(100% - 3.6rem);

        @include lg-up {
            width: 50%;
        }

        &--right {
            margin-top: 30rem;
        }

        &__chapter {
            position: relative;
            transition: all 0.5s ease;
            opacity: 1;
            margin-top: 8.4rem;

            @include lg-up {
                margin-top: 18.4rem;
            }

            &--hidden {
                height: 0;
                opacity: 0;
                margin-top: 0;
            }

            & h2 {
                font-size: 5rem;
            }

            &--left {
                &-link {
                    content: '';
                    position: absolute;
                    top: 0;
                    width: 2.5rem;
                    height: 2.5rem;
                    background-color: $primary;
                    border: 0.5rem solid $white;
                    border-radius: 50%;
                    right: -4rem;
                    z-index: 2;
                }
            }

            &--right {
                &-link {
                    content: '';
                    position: absolute;
                    top: 0;
                    width: 2.5rem;
                    height: 2.5rem;
                    background-color: $primary;
                    border: 0.5rem solid $white;
                    border-radius: 50%;
                    left: -4.2rem;
                    z-index: 2;
                    
                    @include lg-up {
                        left: -4rem;
                    }
                }
            }


            &__img {
                width: 26rem;
                height: 16rem;
                object-fit: cover;
            }
        }
    }

    &__more {
        &__wrapper {
            position: relative;

            &::after {
                content: '';
                position: absolute;
                top: -2.2rem;
                left: 0.4rem;
                transform: 0;
                width: 0.6rem;
                height: 0.6rem;
                z-index: 2;
                box-shadow: 
                0 -1.2rem 0 $gray-600,  /* First dot above */
                0 -2.4rem 0 $gray-600,  /* Second dot above */
                0 -3.6rem 0 $gray-600;  /* Second dot above */

                @include lg-up {
                    left: 50%;
                    transform: translate(-50%, 0);
                }
            }
        }
    }
}
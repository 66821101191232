.iconlist {
  &__item {
    &__icon {
      width: 7rem;
      
      @include lg-up {
        width: 40rem;
      }
      
      & svg {
        width: 100%;
        height: 100%;
        fill: #8C8279;
      }
    }
  }
}

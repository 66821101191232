.btn {
  position: relative;
  padding: 1.4rem 2.4rem;
  transition: all 0.5s ease;
  border: none;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  gap: 1.2rem;
  min-width: fit-content;
  max-width: fit-content;

  &--default {
    background-color: transparent;
    border: 0.1rem solid;
    @include lightDarkMode(border-color, $cta, $cta-dark);
    @include lightDarkMode(color, $cta, $cta-dark);

    &:hover {
      @include lightDarkMode(color, $cta-hover, $cta-hover-dark);
      @include lightDarkMode(border-color, $cta-hover, $cta-hover-dark);
    }
    &:active {
      @include lightDarkMode(color, $cta-selected, $cta-selected-dark);
      @include lightDarkMode(border-color, $cta-selected, $cta-selected-dark);
    }
  }

  &--primary {
    @include lightDarkMode(background, $primary, $white);
    @include lightDarkMode(color, $white, $primary);

    &:hover {
      background-color: rgba($color: $primary, $alpha: 0.8);
    }

    &:active {
      @include lightDarkMode(background, $cta-selected, $cta-selected-dark);
    }


    & svg {
      fill: $white;
      transition: all 0.5s ease;
    }
  }

  &--ghost {
    background-color: transparent;
    border: 0.1rem solid #CCD7E9;
    color: #CCD7E9;

    &:hover {
      border-color: $white;
      color: $white;
    }
  }

  &--back {
    color: $primary;

    & svg {
      fill: $primary;
      transition: all 0.5s ease;
    }

    &:hover {
      & svg {
        transform: translateX(-1.2rem);
      }
    }
  }

  &--wired {
    border: 0.3rem solid $primary;
    color: $primary;

    &:hover {
      background-color: $primary;
      color: $white;

      & svg {
        fill: $white;
      }
    }

    & svg {
      fill: $primary;
      transition: all 0.5s ease;
    }
  }

  &--light {
    background-color: transparent;
    border: 0.1rem solid;
    @include lightDarkMode(color, $white, $primary);
    @include lightDarkMode(border-color, $white, $primary);

    &:hover {
      @include lightDarkMode(color, $cta, $cta-dark);
      @include lightDarkMode(border-color, $cta, $cta-dark);
    }
  }

  &--secondary {
    background-color: $white;
    color: $primary;

    &:hover {
      background-color: rgba($white, 0.8);
    }
  }

  &.right {
    flex-direction: row-reverse;
  }

  &--inline {
    @include lightDarkMode(color, $cta, $cta-dark);
    background: none;
    font-size: 1.8rem;

    &:hover {
      @include lightDarkMode(color, $cta-hover, $cta-hover-dark);
      @include lightDarkMode(border-color, $cta-hover, $cta-hover-dark);
    }

    &:active {
      @include lightDarkMode(color, $cta-selected, $cta-selected-dark);
      @include lightDarkMode(border-color, $cta-selected, $cta-selected-dark);
    }
  }
}
.news-teaser {
  .date {
    font-size: 1.4rem;
    color: rgba($black, 0.32);
  }

  & img {
    object-fit: cover;
  }

  &__link {
    color: light-dark($primary, $cta-hover-dark);
    display: flex;
    align-items: center;

    .icon {
      width: 1.6rem;
      height: 1.6rem;
      margin-left: 1.2rem;
      color: light-dark($primary, $cta-hover-dark);
    }
  }

  &__section-title {
      text-transform: uppercase;
  }

  &__ticker {
    max-width: fit-content;
    padding-top: .4rem;
    padding-bottom: .4rem;
    top: 0;
    right: 0;
  }

  &.medium {
    .row.shadow {
      display: flex;
      flex-direction: column-reverse;
    }
  }
}




html {
  // important to set the html base to 10px
  font-size: 62.5%;
  box-sizing: border-box;
  scroll-behavior: smooth;
}

*, *:before, *:after {
  box-sizing: inherit;
  // background-color: light-dark($white, $black);
  // color: light-dark(var(--light-color), var(--dark-color));
  color: light-dark($body-color, $text-primary-dark);
}

body {
  font-size: 1.6rem;
  margin: 0;
  padding: 0;
}

.row {
  margin: 0;
}

.no-gutters {
  margin-right: 0;
  margin-left: 0;

  > .col,
  > [class*="col-"] {
    padding-right: 0;
    padding-left: 0;
  }
}

//Section margin-top

section.container.share + section.container {
  margin-top: 0;
}

section.container,
section.container-fluid {
  margin-top: $container-margin-sm;

  @include md-up {
    margin-top: $container-margin;
  }
}

section.container--small {
  margin-top: $container-margin-sm;
  max-width: 86.4rem;
  margin-left: auto;
  margin-right: auto;
  padding: 0 2.4rem;

  @include md-up {
    margin-top: $container-margin;
    padding: 0;
  }
}

//selecting Text on website
::-moz-selection { /* Code for Firefox */
  color: #ffffff;
  background: $primary;
}

::selection {
  color: #ffffff;
  background: $primary;
}

img {
  -webkit-user-drag: none;
  user-select: none;
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
}

.container__wrapper {
  width: 100%;
  max-width: 144rem;
  margin-left: auto;
  margin-right: auto;
  padding: $spc-6 $spc-3;

  @include md-up {
    padding: 4.8rem 4.8rem 4.8rem 11.6rem;
  }

  @include lg-up {
    padding: 6rem 4.8rem 6rem 4.8rem;
  }

  @include xl-up {
    padding: 6rem 6.4rem 6rem 14rem;
  }

  @include xxl-up {
    max-width: 192rem;
  }
}

main {
  padding-top: 7.7rem;

  @include lg-up {
    padding-top: 9.6rem;
  }
}

.js-scrollsmoother {
  overflow: hidden;
}
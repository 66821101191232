.contactbanner {
    @include lg-up {
        padding: 5.6rem 4.8rem 5.6rem 4.8rem;
    }

    @include xl-up {
        padding: 5.6rem 6.4rem 5.6rem 14rem;
    }

    &__item {
        &__img {
            aspect-ratio: 3 / 4;
            width: 100%;
            
            @include lg-up {
                height: 30rem;
            }

            @include xl-up {
                height: 34rem;
            }

            &--logodot {
                top: -1.6rem;
                transform: rotate(90deg) translateY(50%);
                left: 50%;

                @include md-up {
                    transform: translateY(-50%) !important;
                    top: 50%;
                    left: -1.6rem;
                }
            }
        }

        &__content {
            & a {
                font-size: 1.8rem;
            }
        }
    }
}
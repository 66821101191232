.footer {
  padding: 0;

  @include lg-up {
    padding: 5.6rem 4.8rem 5.6rem 4.8rem;
  }

  @include xl-up {
    padding: 5.6rem 6.4rem 5.6rem 14rem;
  }

  &__inner {
    transform: translateY(10rem);
    transition: all 0.7s ease 0.1s;
  }

  &__animate {
    & .footer__inner {
      transform: translateY(0) !important;
    }
  }

  * {
    color: $black;
  }

  svg {
    fill: $black;
  }

  &__address {
    p {
      margin: 0;
      font-size: 1.6rem;
    }

    svg {
      width: 1.4rem;
      height: 1.4rem;
    }
  }

  &__helpfullinks {
    border-bottom: 0.1rem solid rgba($color: $black, $alpha: 0.2);

    @include lg-up {
     border: 0; 
    }

    &__openbtn {
      & svg {
        transition: all $easing;
      }

      &--open {
        & svg {
          transform: rotate(180deg);
        }
      }
    }

    &__content {
      height: 0;
      transition: all $easing;

      @include lg-up {
        height: unset;
      }
      
      &--open {
        height: 20rem;

        @include lg-up {
          height: unset;
        }
      }
    }
  }

  &__adress {
    &__title {
      font-size: 2rem !important;
    }
  }

  &__language {
    &-link {
      &:not(:last-child):after {
        content: " | ";
        padding: 0 10px;
        color: $black;
      }
    }
  }

  &__sub {
    font-size: $small-font-size;
    text-align: center;
  }

  &__siblingnav {
    @include lg-up {
      gap: 5.6rem;
    }
    
    &__wrapper {
      @include lg-up {
        width: 20rem;
      }
    }

    &__prev {
      & svg {
        transition: all 0.7s ease;
        transform: translateX(0);
      }
      
      &:hover {
        & svg {
          transform: translateX(-0.5rem);
        }
      }
    }

    &__next {
      & svg {
        transition: all 0.7s ease;
        transform: translateX(0);
      }
      
      &:hover {
        & svg {
          transform: translateX(0.5rem);
        }
      }
    }
  }
}
$hamburgerWidth: 36rem;

@keyframes headerAnim {
 0% {
  opacity: 0;
}
100% {
  opacity: 1;
 }
}

.header {
  background-color: $white;
  top: 0;
  z-index: 11;
  opacity: 0;
  animation: headerAnim 1s ease 0.4s forwards;

  &__inner {
    padding: 1.2rem 0;
    max-width: 144rem;

    @include xxl-up {
      max-width: 192rem;
    }
  }

  &__lang {
    & a:first-child {
      padding-right: 0.8rem;
      border-right: 0.1rem solid $gray-300;
    }
  }

  &__logo {
    transition: all 1s ease;
    position: absolute;
    z-index: 99;
    top: 0.7rem;
    
    @include lg-up {
      top: 1.5rem;
    }

    &__wrapper {
      @include lg-up {
        height: 9.6rem;
      }
    }
  }

  &__nav {
    &__item {
      position: relative;

      &-active {
        & a {
          transition: all $easing;
          color: $black !important;
        }

        &::after {
          opacity: 1 !important;
          width: 0.8rem !important;
          height: 0.8rem !important;
          background-color: $black !important;
        }
      }
      
      &::after {
        content: '';
        width: 0rem;
        height: 0rem;
        background-color: $primary;
        border-radius: 50%;
        position: absolute;
        bottom: 0.8rem;
        left: 50%;
        transform: translateX(-50%);
        opacity: 0;
        transition: all $easing;
        user-select: none;
        pointer-events: none;
      }

      & a {
        transition: all $easing;
      }
      
      &:hover {
        & a {
          transition: all $easing;
        }

        &::after {
          opacity: 1;
          width: 0.8rem;
          height: 0.8rem;
        }
      }
    }
  }
  
  $height-icon: 1rem;
  $width-line: 1.6rem;
  $height-line: 0.2rem;

  $rotation: 45deg;
  $translateY: calc($height-icon / 2);
  $translateX: 0;

  #hamburger-icon {
    width: $width-line;
    height: $height-icon;
    
    .line {
      width: $width-line;
      height: $height-line;
      left: 0;
      transition: all 0.3s;
      
      &.line-1 {
        top: 0;
      }
      &.line-2 {
        top: 50%;
      }
      &.line-3 {
        top: 100%;
      }
    }
    &:hover, &:focus {
      .line-1 {
          transform: translateY(calc($height-line / 2 * -1));
      }
      .line-3 {
          transform: translateY(calc($height-line / 2));
      }  
    }
    &.active {
      .line-1 {
        transform: translateY($translateY) translateX($translateX) rotate($rotation);
      }
      .line-2 {
        opacity: 0;
      }
      .line-3 {
        transform: translateY(calc($translateY * -1)) translateX($translateX) rotate(calc($rotation * -1));
      }
    }
  }

  .hamburger-menu {
    bottom: 0;
    transform: translate(100%, 100%);
    z-index: 12;
    right: 0;
    width: 100%;
    transition: 0.9s all ease;
    height: 100vh;
    background: light-dark($white, $gray-800);

    @include md-up {
      width: 50%;
    }

    &::before {
      content: '';
      width: 100%;
      height: 100%;
      background-color: rgba($color: $white, $alpha: 1);
      position: absolute;
      top: 0;
      left: 0;
      z-index: -1;
      transition: 0.7s all ease-out;
    }

    &::after {
      // content: '';
      width: 100%;
      height: 100%;
      background-color: $primary;
      position: absolute;
      top: 0;
      left: 0;
      z-index: -2;
      transition: 0.5s all ease-out;
      
      @include md-up {
        width: 0;
      }
    }

    &.active {
      transform: translate(-$hamburgerWidth, 100%) !important;
    }

    & nav {
      border-bottom: 0.1rem solid rgba($color: $black, $alpha: 0.1);
    }

    a {
      color: light-dark($black, $white);
      font-size: 2.4rem;

      &:hover {
        color: light-dark($cta, $cta-dark);
      }

      svg {
        width: 1.6rem;
        height: 1.6rem;
        padding: 0.3rem;
        margin-left: 1rem;
      }
    }

    &__item {
      &.has-children {
        .hamburger-menu__submenu {
          top: 0;
          width: $hamburgerWidth;
          transform: translate(calc(#{$hamburgerWidth} - 3.2rem), 0);
          height: 100%;
          color: $black;
      }
      }
    }
  }

  &.active {
    .hamburger-menu {
      transform: translate(0, 100%);
      transition: 0.9s transform ease;

      &::before {
        left: -5rem;
      }

      &::after {
        left: -100%;
        transition: 1s all ease;
      }
    }
  }
}

.activeHeader {
  margin-top: unset;
  background-color: $white;
  padding: 0 !important;
  transition: all 1s ease;
}

.inactiveHeader {
  transform: translateY(-100%);
  transition: all 1s ease;

  & .header__logo {
    transition: all 1s ease;
    top: 8rem;
    
    @include lg-up {
      top: 11rem;
    }
  }
}

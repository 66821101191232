.standorte {
    &__card {
        border: 0.5rem solid rgba($color: $secondary, $alpha: 0.2);
        transition: all $easing;
        width: 100%;

        @include md-up {
            width: calc(50% - 1.2rem);
            margin: 0 1.2rem;
            
            &:nth-child(2n+1) {
                margin-left: 0;
            }
    
            &:nth-child(2n+2) {
                margin-right: 0;
            }
        }

        @include xl-up {
            width: calc(33% - 1.2rem);

            &:nth-child(2n+1) {
                margin-left: 1.2rem;
            }
    
            &:nth-child(2n+2) {
                margin-right: 1.2rem;
            }

            &:nth-child(3n+1) {
                margin-left: 0;
            }
    
            &:nth-child(3n+3) {
                margin-right: 0;
            }
        }
        
        &:hover {
            border-color: $secondary;
        }
    }
}
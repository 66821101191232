@import '_config/mixins';
@import '~bootstrap/scss/functions';
@import '_config/variables';
@import '_config/colorVariables';
@import '_config/fontVariables';

@import '_config/fonts';
@import '_config/utilities';
@import 'bootstrap/scss/bootstrap-grid.scss';
@import 'bootstrap/dist/css/bootstrap-utilities.min.css';
@import '_config/settings';
@import '_config/cookiebot';

@import 'swiper/css';
@import 'swiper/css/navigation'; // Only if using navigation
@import 'swiper/css/pagination'; // Only if using pagination


@import '01-atoms/typography/typography';
@import '01-atoms/button/button';
@import '01-atoms/icons/icon';
@import '01-atoms/navigation/navigation';
@import '01-atoms/readmore/readmore';
@import '01-atoms/inputs/inputs';
// @import '01-atoms/galeryItem/galeryItem';
// @import '01-atoms/personProfile/personProfile';
// @import '01-atoms/sectionTitle/section-title';

// @import '02-molecules/accordion/accordion';
@import '02-molecules/alerts/alerts';
@import '02-molecules/hero/hero';
// @import '02-molecules/popup/popup';
@import '02-molecules/iconList/iconList';
// @import '02-molecules/share/share';
@import '02-molecules/slider/slider';
@import '02-molecules/lead/lead';
@import '02-molecules/search/search';
@import '02-molecules/contactBlock/contactBlock';
@import '02-molecules/testimonials/testimonials';
@import '02-molecules/newsTeaser/newsTeaser';
@import '02-molecules/pageTitle/pageTitle';
@import '02-molecules/contactInfo/contactInfo';
@import '02-molecules/pagination/pagination';
// @import '02-molecules/filter/filter';
// @import '02-molecules/newsGrid/newsGrid';
@import '02-molecules/content/content';
// @import '02-molecules/dialogues/dialogues';
@import '02-molecules/notFound/notFound';
@import '02-molecules/numbers/numbers';
@import '02-molecules/twoColumn/twoColumn';
@import '02-molecules/awards/awards';
@import '02-molecules/teamgrid/teamgrid';
@import '02-molecules/twocards/twocards';
@import '02-molecules/onecard/onecard';
@import '02-molecules/contactbanner/contactbanner';
@import '02-molecules/downloadarea/downloadarea';
@import '02-molecules/kontaktcards/kontaktcards';
@import '02-molecules/standorte/standorte';
@import '02-molecules/history/history';
@import '02-molecules/cardslider/cardslider';
@import '02-molecules/threecards/threecards';
@import '02-molecules/zitat/zitat';
@import '02-molecules/iframe/iframe';
@import '02-molecules/jobteaser/jobteaser';

@import '03-organisms/header/header';
@import '03-organisms/search/search';
@import '03-organisms/multitext/multitext';
@import '03-organisms/footer/footer';

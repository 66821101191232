.awards {
    &__more {        
        padding-bottom: 13rem !important;

        @include lg-up {
            padding-bottom: 20rem !important;
        }
    }

    &__awards {
        &__img {
            width: 20rem;
        }
    }

    & .swiper {
        &-button {
            &-disabled {
                background-color: $gray-200 !important;
                opacity: 1;
            }

            &-prev {
                position: absolute;
                bottom: 6.4rem;
                left: unset;
                top: unset;
                background-color: $primary;
                width: 4.8rem;
                height: 4.8rem;
                padding: 1.2rem;
                transition: all $easing;
                right: calc(50% + 2.5rem);
                transform: translateX(50%);
                
                &::after {
                    color: $white;
                    font-size: 2.2rem;
                }
            }
            
            &-next {
                position: absolute;
                bottom: 6.4rem;
                top: unset;
                background-color: $primary;
                width: 4.8rem;
                height: 4.8rem;
                padding: 1.2rem;
                transition: all $easing;
                right: calc(50% - 2.5rem);
                transform: translateX(50%);

                &::after {
                    color: $white;
                    font-size: 2.2rem;
                }
            }
        }
    }
}
:root {
  color-scheme: light;
}

// Color system
// BRAND COLORS ------------------------

$primary:     #cb003d;
$primary-2:   #cb003d;
$secondary:   #A2B2C8;
$secondary-2: #A2B2C8;

$primary-dark:   rgba($primary, 60%);
$secondary-dark: rgba($secondary, 70%);


$primary-50:  rgba($primary, 5%);
$primary-100: rgba($primary, 10%);
$primary-200: rgba($primary, 20%);
$primary-300: rgba($primary, 30%);
$primary-400: rgba($primary, 40%);
$primary-500: rgba($primary, 50%);
$primary-600: rgba($primary, 60%);
$primary-700: rgba($primary, 70%);
$primary-800: rgba($primary, 80%);
$primary-900: rgba($primary, 90%);



// STATE COLORS --------------------------------
/* colors light mode */
$states-error: #d94d4d;
$states-error-shade: #d94d4d0d;
$states-info: #1757bd;
$states-info-shade: #1757bd0d;
$states-success: #78a220;
$states-success-shade: #78a2200d;
$states-warning: #dea71a;
$states-warning-shade: #dea71a0d;

/* colors dark mode */
$states-error-dark: #d94d4dcc;
$states-error-shade-dark: #d94d4d33;
$states-info-dark: #1757bdcc;
$states-info-shade-dark: #1757bd33;
$states-success-dark: #78a220cc;
$states-success-shade-dark: #78a22033;
$states-warning-dark: #dea71acc;
$states-warning-shade-dark: #dea71a33;
// END OF STATE COLORS

$error:       $states-error;
$info:        $states-info;
$success:     $states-success;
$warning:     $states-warning;
$danger:      $states-error;


// STANDART COLORS --------------------------------
// scss-docs-start gray-color-variables
$white:    #fff !default;
$gray-50:  #F6F7F9 !default;
$gray-100: #e5e5e5 !default; 
$gray-200: #cccccc !default;
$gray-300: #b3b3b3 !default;
$gray-400: #999999 !default;
$gray-600: #666666 !default;
$gray-700: #4d4d4d !default;
$gray-800: #333333 !default;
$gray-900: #191919 !default;
$black:    #000 !default;

/* colors light mode */
$brighten-50:  rgba($white, 5%);
$brighten-100: rgba($white, 10%);
$brighten-200: rgba($white, 20%);
$brighten-300: rgba($white, 30%);
$brighten-400: rgba($white, 40%);
$brighten-600: rgba($white, 60%);
$brighten-800: rgba($white, 80%);

/* colors dark mode */
$brighten-50-dark:  rgba($black, 5%);
$brighten-100-dark: rgba($black, 10%);
$brighten-200-dark: rgba($black, 20%);
$brighten-300-dark: rgba($black, 30%);
$brighten-400-dark: rgba($black, 40%);
$brighten-600-dark: rgba($black, 60%);
$brighten-800-dark: rgba($black, 80%);

/* colors light mode */
$darken-50:  rgba($black, 5%);
$darken-100: rgba($black, 10%);
$darken-200: rgba($black, 20%);
$darken-300: rgba($black, 30%);
$darken-400: rgba($black, 40%);
$darken-600: rgba($black, 60%);
$darken-800: rgba($black, 80%);

/* colors dark mode */
$darken-50-dark:  rgba($black, 5%);
$darken-100-dark: rgba($black, 10%);
$darken-200-dark: rgba($black, 20%);
$darken-300-dark: rgba($black, 30%);
$darken-400-dark: rgba($black, 40%);
$darken-600-dark: rgba($black, 60%);
$darken-800-dark: rgba($black, 80%);
// scss-docs-end gray-color-variables



// CTA COLORS --------------------------------
/* colors light mode */
$cta:           $primary;
$cta-disabled:  $primary;
$cta-hover:     $primary;
$cta-label:     #ffffff;
$cta-selected:  #666666;
$cta-shade:     #1757bd1a; 

/* colors dark mode */
$cta-dark:          #ffffff;
$cta-disabled-dark: #ccd7e999;
$cta-hover-dark:    #ffffffcc;
$cta-label-dark:    $primary;
$cta-selected-dark: #cccccc;
$cta-shade-dark:    #ccd7e94d;

// BODY
$body-bg:                   $white !default;
$body-color:                $gray-900 !default;



// LAYOUT COLORS --------------------------------
/* colors light mode */
$component-bg: #ffffff00;
$component-shade: #f9f9f9;
$dialogue-backdrop: #00000080;
$page-bg: #e7f1fa;
$seperator: #0000001a;

/* colors dark mode */
$component-bg-dark: #042f54;
$component-shade-dark: #042f54;
$dialogue-backdrop-dark: #ffffff80;
$page-bg-dark: #042f54;
$seperator-dark: #ffffff4d;





// SHADOWS ----------------------
.shadow {
  box-shadow: 0 0.1rem 0.2rem 0 rgba($black, 0.25) !important;
  transition: 0.3s all ease;
  cursor: pointer;

  &:hover {
    box-shadow: 0 0.3rem 1rem 0 rgba($black, 0.25) !important;
    transition: 0.3s all ease;
  }
}

// INPUTS ------------------------
$radio-color: rgba(#4E75B2, 0.8);
$radio-checked: #1757BD;


// LINKS ------------------------
$link-color:                $primary !default;
$link-shade-percentage:     20% !default;
$link-hover-color:          shift-color($link-color, $link-shade-percentage) !default;


// BUTTONS -----------------------
$active-color: $cta-selected;


// BORDERS -----------------------
$border-color:                $gray-300 !default;
$border-color-translucent:    rgba($black, .175) !default;

// TEXT --------------------------
/* colors light mode */
$states-text: #ffffff;
$text-headlines: $primary;
$text-primary: $primary;
$text-secondary: #042f5499;
$text-sections: $primary;
$text-tertiary: #00000066;

/* colors dark mode */
$states-text-dark: #ffffffcc;
$text-headlines-dark: #ffffff;
$text-primary-dark: #ffffff;
$text-secondary-dark: #ffffff99;
$text-sections-dark: #ffffff99;
$text-tertiary-dark: #ffffff66;

$headings-color:              null !default;
$text-muted:                  $gray-600 !default;
$blockquote-footer-color:     $gray-600 !default;


// TABLES -------------------------
/* colors light mode */
$data-tables-border: #00000080;
$data-tables-td-bg-even: #ffffff;
$data-tables-td-bg-hover: #147bd11a;
$data-tables-td-bg-odd: #ffffff;
$data-tables-td-bg-selected: #147bd133;
$data-tables-td-text: $text-primary;
$data-tables-td-text-disabled: $darken-400;
$data-tables-td-text-selected: $text-primary;
$data-tables-th-bg: #ffffff;
$data-tables-th-text-primary: $text-primary;
$data-tables-th-text-secondary: #147bd1cc;

/* colors dark mode */
$data-tables-border-dark: #ffffff1a;
$data-tables-td-bg-even-dark: #ffffff1a;
$data-tables-td-bg-hover-dark: #1757bd1a;
$data-tables-td-bg-odd-dark: #ffffff00;
$data-tables-td-bg-selected-dark: #1757bd33;
$data-tables-td-text-dark: #ffffffcc;
$data-tables-td-text-disabled-dark: #ffffff66;
$data-tables-td-text-selected-dark: #ffffff;
$data-tables-th-bg-dark: #042f54;
$data-tables-th-text-primary-dark: #ffffff;
$data-tables-th-text-secondary-dark: #ffffff99;



.content {
  p {
    max-width: 86.4rem;
    margin: 0 auto;
  }

  img {
    margin: 3.2rem 0;
    width: 100%;
  }
}
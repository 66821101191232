.twocolumn {
  @include lg-up {
    padding: 5.6rem 4.8rem 5.6rem 4.8rem;
  }

  @include xl-up {
    padding: 5.6rem 6.4rem 5.6rem 14rem;
  }

  &__content {
    padding: $spc-6 $spc-3;

    @include md-up {
      padding: 5.6rem 4.8rem 5.6rem 11.6rem;
    }

    @include lg-up {
      padding: 6.4rem;
    }
  }

  &__logodot {
    top: unset;
    bottom: -1.6rem;
    left: 50%;
    transform: translateX(-50%) rotate(90deg);

    @include lg-up {
      top: 50%;
      transform: translateY(-50%) rotate(180deg);
      left: -1.6rem;
    }
  }
}
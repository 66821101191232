.ml-auto {
  margin-left: auto;
}
.mr-auto {
  margin-right: auto;
}

// Background colors
.bg-primary {
  background-color: light-dark($primary, $primary-dark) !important;
}

.bg-secondary {
  background-color: light-dark($secondary, $secondary-dark) !important;
}

.bg-white {
  background-color: light-dark($white, $black) !important;
}

.bg-black {
  background-color: light-dark($black, $white) !important;
}

.bg-gray-50 {
  background-color: light-dark($gray-50, $gray-800) !important;
}

.bg-gray-100 {
  background-color: light-dark($gray-100, $gray-800) !important;
}

.bg-gray-200 {
  background-color: light-dark($gray-200, $gray-800) !important;
}

.bg-gray-300 {
  background-color: light-dark($gray-300, $gray-700) !important;
}

.bg-gray-400 {
  background-color: light-dark($gray-400, $gray-600) !important;
}

// Text colors
.text-primary {
  color: light-dark($primary, $text-primary-dark) !important;
  
  & h1 {
    color: light-dark($primary, $text-primary-dark) !important;
  }
  
  & > svg {
    fill: light-dark($primary, $text-primary-dark) !important;
  }
}
.text-secondary {
  color: light-dark($secondary, $text-secondary-dark) !important;
}

.text-white {
  color: light-dark($white, $black) !important;
  
  & h1, h2, h3, h4, p {
    color: light-dark($white, $black) !important;
  }

  & > svg {
    fill: light-dark($white, $black) !important;
  }
}

.text-black{
  color: light-dark($black, $white) !important;

  & > svg {
    fill: light-dark($black, $white) !important;
  }
}

.link-wrap {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: block;
}

// cursort pointer
.c-p {
  cursor: pointer;
}

// flex positioning

.flex-center-center {
  display: flex;
  justify-content: center;
  align-items: center;
}

.flex-center-between {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.flex-center-start {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.flex-center-end {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.opacity-60 {
  opacity: 0.6;
}

.logodot {
  position: absolute;
  border-radius: 50%;
  width: 3.2rem;
  height: 3.2rem;
  background-color: $white;
  z-index: 9;

  &::after {
    content: '';
    width: 1.6rem;
    height: 3.2rem;
    content: "";
    position: absolute;
    background-color: $primary;
    border-radius: 1.6rem 0 0 1.6rem; /* Makes the right side curved */
    top: 0;
    left: 0;
  }
}

.animate {
  opacity: 1 !important;
  transform: translateY(0) !important;
  transition: opacity $easing, transform $easing;
}

.js-scrollinto {
  opacity: 0;
  transform: translateY(3rem);
}
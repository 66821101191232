.iframe {
    &__news {
        padding: $spc-6 $spc-3;

        @include md-up {
            padding: 4.8rem 4.8rem 4.8rem 11.6rem;
        }
    
        @include lg-up {
            padding: 6rem 4rem 6rem 4rem;
        }
    
        @include xl-up {
            padding: 6rem 6.4rem 6rem 12.3rem;
        }
    }

    &__iframe {
        height: 120vh;

        &--news {
            height: 1497px;
            
            @include lg-up {
                height: 1644px;
            }

            @include xl-up {
                height: 1242px;
            }
        }
    }

    &__button {
        &__job {
            background-color: $white;
            font-size: 1.6rem;
        }
    }
}
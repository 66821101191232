body {
  font-family: $font-family-base;
  font-weight: $font-weight-base;
  font-style: normal;
  color: light-dark($body-color, $text-primary-dark);
}

h1,
h2,
h3,
h4,
h5,
h6,
.h1,
.h2,
.h3,
.h4,
.h5,
.h6 {
  // font-family: $heading__font-family;
  // font-weight: $heading__font-weight;
  // font-style: normal;
  // text-rendering: auto;
  // color: $body__font-color;
  // font-weight: 500 !important;
  margin-top: 0;
}

h1,
.h1 {
  font-weight: 700;
  font-size: $h1-font-size;
  font-family: 'Fellix-Bold';
  line-height: 120%;

  @include md-up {
    font-size: $h1-lg-font-size;
  }
}

h2,
.h2 {
  font-weight: 700;
  font-size: $h2-font-size;
  line-height: 120%;
  font-family: 'Fellix-Bold';

  @include md-up {
    font-size: $h2-lg-font-size;
  }
}

h3,
.h3 {
  font-weight: 700;
  font-size: $h3-font-size;
  line-height: 120%;

  @include md-up {
    font-size: $h3-lg-font-size;
  }
}

h4,
.h4 {
  font-weight: 700;
  font-size: $h4-font-size;
  line-height: 120%;
}

h5,
h6,
.h5,
.h6 {
  font-size: $h5-font-size;
  line-height: 120%;
}

p {
  text-rendering: auto;
  font-weight: 400;
  font-size: $font-size-base;
  line-height: 135%;
  margin-top: 0;

  @include md-up {
    font-size: $font-size-lg-base;
  }

  &:last-of-type {
    margin-bottom: 0;
  }

  &.title_lg {
    font-size: 2.1rem;
    line-height: 120%;
    font-weight: 700;
  }

  &.section-title {
    font-size: 2.1rem;
    line-height: 120%;
    font-weight: 400;
    margin-bottom: 0.8rem;
    text-align: center;
    text-transform: uppercase;
  }
}

a {
  text-decoration: none;
}

ul,
ol {
  list-style-position: outside;
  margin-left: 0;
  padding-left: 1.8rem;
  font-size: $font-size-base;

  @include md-up {
    font-size: $font-size-lg-base;
  }
}

li {
  margin-bottom: 1.2rem;
}

ol {
  padding-left: 2.3rem;
}

ul ul,
ol ol {
  margin-left: 0;
}

hr {
  // border-width: $hr__border-width;
  // border-style: $hr__border-style;
  // border-color: $hr__border-color;
  // margin-top: $hr__margin-top;
  // margin-bottom: $hr__margin-bottom;
}

small {
  // color: $color__accent--dark;
}

blockquote {
  font-weight: 400;
  font-size: 1.8rem;
  line-height: 135%;
}

.text {
  &-lead {
    font-family: $font-family-base;
    font-style: normal;
    font-weight: 400;
    font-size: 2.1rem;
    line-height: 135%;

    @include lg-up {
      font-size: $lead-font-size;
    }
    
    & p {
      font-family: $font-family-base;
      font-style: normal;
      font-weight: 400;
      line-height: 135%;
      font-size: 2.1rem;
      
      @include lg-up {
        font-size: $lead-font-size;
      }
    }
  }
  
  &-subtitle {
    opacity: 0.6;
    font-size: 1.6rem;
    margin-bottom: 0.8rem;
  }
  
  &-strong {
    font-family: 'Fellix-Bold';
  }

  &-section {
    font-weight: 400;
    font-size: 2.1rem;
    line-height: 120%;
  }

  &-large {
    font-weight: 300;
    font-size: 2.8rem;
    line-height: 135%;
    margin-bottom: 0;

    @include lg-up {
      font-size: 3.6rem; 
    }
  }

  &-small {
    font-weight: 400;
    font-size: 1.44rem;
    line-height: 135%;
    margin-bottom: 0;
    margin-top: 0.4rem;
  }
}
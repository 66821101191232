.teamgrid {
    &__item {
        border: 0.5rem solid rgba($color: $secondary, $alpha: 0.2);
        transition: all $easing;
        
        &:hover {
            border-color: $secondary;
        }

        @include md-up {
            width: calc(50% - 1.8rem);
            margin: 0 1.2rem;

            &:nth-child(2n+1) {
                margin-left: 0;
            }
    
            &:nth-child(2n+2) {
                margin-right: 0;
            }
        }

        @include xl-up {
            width: calc(25% - 1.8rem);

            &:nth-child(2n+1) {
                margin-left: 1.2rem;
            }
    
            &:nth-child(2n+2) {
                margin-right: 1.2rem;
            }

            &:nth-child(4n+1) {
                margin-left: 0;
            }
    
            &:nth-child(4n+4) {
                margin-right: 0;
            }
        }

        &__img {
            @include md-up {
                height: 36.6rem;
            }

            @include lg-up {
                height: 61rem;
            }

            @include xl-up {
                height: 36.6rem;
            }

            & img {
                object-position: top;
            }
        }

        &__content {
            & h3 {
                font-size: 2.1rem;
                hyphens: auto;
            }

            & p {
                font-size: 1.6rem;
                hyphens: auto;
            }
        }

        &__popup {
            height: 100vh;
            z-index: 110;
            transition: all 0.3s ease;
            
            &--inactive {
                transform: scale(0);

                & .teamgrid__item__popup__close::after {
                    background-color: rgba($color: $white, $alpha: 0);
                    transition: all 0s;
                }
            }

            &__inner {
                max-width: 96rem;
                height: 100%;
                
                @include lg-up {
                    border: 0.6rem solid $primary;
                    height: unset;
                }
            }

            &__img {
                aspect-ratio: 4 / 3;
                border: 0.5rem solid rgba($color: $secondary, $alpha: 0.2);

                @include lg-up {
                    height: 39rem;
                }
            }

            &__close {
                &::after {
                    content: '';
                    position: absolute;
                    width: 100%;
                    height: 100%;
                    top: 0;
                    left: 0;
                    z-index: -1;
                    background-color: rgba($color: $white, $alpha: 0.6);
                    transition: all 0.3s ease 0.2s;
                }
            }

            &__text {
                & p {
                    font-size: 2.1rem;
                }
            }
        }
    }
}
.search {
    z-index: 10;
    height: 100vh;
    max-height: 100vh;
    overflow: scroll;
    width: 100%;
    transition: all $easing;

    &--closed {
        transform: translateY(-120%);
    }

    &__close {
        width: fit-content;
        border: 0.3rem solid $primary;
        padding: 1.7rem 2.1rem;
        transition: all $easing;
        
        &:hover {
            background-color: $primary;
            
            & svg {
                fill: $white !important;
            }
        }

        & svg {
            transition: all $easing;
        }
    }

    &__skeloton {
        &__item {
            border-bottom: 0.3rem solid $gray-100;
            position: relative;

            &:first-child {
                border-bottom: 0;
            }

            &:after {
                content: '';
                top: 0;
                transform: translateX(100%);
                width: 100%;
                height: 22rem;
                position: absolute;
                z-index: 0;
                animation: slide 1s ease-in-out infinite;
                background: -moz-linear-gradient(left, rgba(255,255,255,0) 0%, rgba(255,255,255,0.8) 50%, rgba(128,186,232,0) 99%, rgba(125,185,232,0) 100%); /* FF3.6+ */
                background: -webkit-gradient(linear, left top, right top, color-stop(0%,rgba(255,255,255,0)), color-stop(50%,rgba(255,255,255,0.8)), color-stop(99%,rgba(128,186,232,0)), color-stop(100%,rgba(125,185,232,0))); /* Chrome,Safari4+ */
                background: -webkit-linear-gradient(left, rgba(255,255,255,0) 0%,rgba(255,255,255,0.8) 50%,rgba(128,186,232,0) 99%,rgba(125,185,232,0) 100%); /* Chrome10+,Safari5.1+ */
                background: -o-linear-gradient(left, rgba(255,255,255,0) 0%,rgba(255,255,255,0.8) 50%,rgba(128,186,232,0) 99%,rgba(125,185,232,0) 100%); /* Opera 11.10+ */
                background: -ms-linear-gradient(left, rgba(255,255,255,0) 0%,rgba(255,255,255,0.8) 50%,rgba(128,186,232,0) 99%,rgba(125,185,232,0) 100%); /* IE10+ */
                background: linear-gradient(to right, rgba(255,255,255,0) 0%,rgba(255,255,255,0.8) 50%,rgba(128,186,232,0) 99%,rgba(125,185,232,0) 100%); /* W3C */
                filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00ffffff', endColorstr='#007db9e8',GradientType=1 ); /* IE6-9 */  
            }

            @keyframes slide {
                0% {transform:translateX(-100%);}
                100% {transform:translateX(100%);}
            }

            &--results {
                background-color: $gray-100;
                border-radius: 0.5rem;
                width: 13rem;
                height: 1.6rem;
            }

            &--subtitle {
                background-color: $gray-100;
                border-radius: 0.5rem;
                width: 6rem;
                height: 1.6rem;
            }

            &--title {
                background-color: $gray-100;
                border-radius: 0.5rem;
                width: 15rem;
                height: 2.4rem;
            }

            &--text {
                background-color: $gray-100;
                border-radius: 0.5rem;
                width: 90%;
                height: 4rem;
            }

            &--icon {
                background-color: $gray-100;
                border-radius: 0.5rem;
                width: 3rem;
                height: 3rem;
                position: absolute;
                top: 50%;
                transform: translateY(-50%);
                right: 2.4rem;
            }
        }
    }

    &__form {
        position: relative;

        &__input {
            width: 100%;
            padding: 3.2rem 2.1rem;
            border-width: 0 0 0.2rem 0;
            border-bottom: solid $primary;
            color: $black;
            margin-bottom: 2.1rem;
            font-size: 1.8rem;

            &:focus {
                outline: none;
            }

            &:hover {
                & svg {
                    color: $primary;
                }
            }

            &:not(:placeholder-shown) + label {
                top: 0rem;
                color: rgba($color: $black, $alpha: 0.4);
                font-size: 1.2rem;
            }
        }

        &__searchicon {
            position: absolute;
            top: 2.8rem;
            left: 0;

            & svg {
                fill: rgba($color: $black, $alpha: 0.4);
            }
        }
        
        &__abandonicon {
            position: absolute;
            top: 2.8rem;
            right: 0;
            opacity: 0;
            transition: all $easing;

            &--active {
                opacity: 1;
            }

            & svg {
                fill: rgba($color: $black, $alpha: 0.4);
            }
        }
        
        &__label {
            position: absolute;
            font-size: 1.8rem;
            user-select: none;
            pointer-events: none;
            top: 3.2rem;
            left: 4.8rem;
            transition: all $easing;
            color: rgba($color: $black, $alpha: 0.4);
        }
    }

    &__results {
        &__entry {
            border-bottom: 0.3rem solid rgba($color: $secondary, $alpha: 0.2);

            &:hover {
                & svg {
                    transform: translateX(1rem);
                }
            }

            & h3 {
                font-size: 2.4rem;
            }
            
            &--icon {
                width: 4rem;
            
                & svg {
                    transition: all $easing;
                }
            }
        }
    }
}
.alerts {
  &__alert {
    position: fixed;
    bottom: 0;
    left: 50%;
    transform: translate(-50%, 100%);
    transition: all 0.4s ease;

    &__icon {
      & svg {
        width: 2.6rem;
        height: 2.6rem;
      }
    }

    &__text {
      align-items: center;
      justify-content: space-between;
      min-width: 26rem;
    }

    &__close {
      position: absolute;
      right: 0;
      top: 50%;
      transform: translateY(-50%);
      padding: 1.8rem;

      & svg {
        width: 1.9rem;
        height: 1.9rem;
        color: light-dark($white, $black);
      }
    }
  }

  &__success {
    &-icon {
      background-color: $success;
    }

    &-text {
      background-color: #eff3e2;
    }
  }

  &__warning {
    &-icon {
      background-color: $warning;
    }

    &-text {
      background-color: #ede8df;
    }
  }

  &__error {
    &-icon {
      background-color: $danger;
    }

    &-text {
      background-color: #efe2e2;
    }
  }

  &__info {
    &-icon {
      background-color: $info;
    }

    &-text {
      background-color: #d7dde4;
      color: $black;
    }
  }
}

.alert-show {
  bottom: 3.2rem;
  transform: translate(-50%, 0%);
}
.downloadarea {
    &__item {
        width: 100%;
        border: 0.5rem solid rgba($color: $secondary, $alpha: 0.2);
        transition: all $easing;
        
        @include lg-up {
            width: calc(33% - 1.2rem);
        }
        
        &:hover {
            border-color: $secondary;
        }
    }
}
.cardslider {
    &__cardslider {
        border: 0.5rem solid rgba($color: $secondary, $alpha: 0.2);
        min-height: 33rem;

        @include md-up {
            height: 39rem;
        }
        
        @include xl-up {
            height: 33rem;
        }

        &__img {
            height: 8rem;
            object-fit: contain !important;
        }
    }

    & .swiper {
        &-fade {
            &::after {
                content: '';
                height: 100%;
                width: 0;
                background: $white;
                background: linear-gradient(90deg, rgba(255,255,255,0) 0%, rgba(255,255,255,1) 100%);
                position: absolute;
                top: 0;
                right: -6.4rem;
                z-index: 1;

                @include md-up {
                    width: 5.6rem;
                }

                @include lg-up {
                    width: 7.6rem;
                }
            }
        }


        &-button {
            &-disabled {
                background-color: $gray-200 !important;
                opacity: 1;
            }

            &-prev {
                position: absolute;
                bottom: -7.2rem;
                left: unset;
                top: unset;
                background-color: $primary;
                width: 4.8rem;
                height: 4.8rem;
                padding: 1.2rem;
                transition: all $easing;
                right: calc(50% + 2.5rem);
                transform: translateX(50%);
                
                @include lg-up {
                    transform: unset;
                    right: 5rem;
                }
                
                &::after {
                    color: $white;
                    font-size: 2.2rem;
                }
            }
            
            &-next {
                position: absolute;
                bottom: -7.2rem;
                left: unset;
                top: unset;
                background-color: $primary;
                width: 4.8rem;
                height: 4.8rem;
                padding: 1.2rem;
                transition: all $easing;
                right: calc(50% - 2.5rem);
                transform: translateX(50%);
                
                @include lg-up {
                    transform: unset;
                    right: 0rem;
                }
                
                &::after {
                    color: $white;
                    font-size: 2.2rem;
                }
            }
        }
    }
}
.hero {
  &__background {
    animation: heroImg 0.5s ease forwards;

    @include lg-up {
      height: 64rem;
    }
  }

  &__video {
    animation: heroImg 0.5s ease forwards;
    position: relative;

    @include lg-up {
      height: 64rem;
    }

    &__video {
      height: 100%;
      width: 100%;
    }

    &__play {
      position: absolute;
      top: 50%;
      left: 50%;
      width: 100%;
      height: 100%;
      transform: translate(-50%, -50%);
      z-index: 1;

      & svg {
        height: 100%;
      }
    }
  }

  &__title {
    transform: translateX(10rem);
    opacity: 0;
    animation: heroText 0.5s ease 0.5s forwards;
  }

  &__text {
    transform: translateX(10rem);
    opacity: 0;
    animation: heroText 0.5s ease 0.5s forwards;
  }
  
  &__backbtn {
    transform: translateX(10rem);
    opacity: 0;
    animation: heroText 0.5s ease 0.6s forwards;
  }

  &__onpagenav {
    transition: all $easing, padding-left 0s ease, transform 0s;
    background-color: $white;

    // &__parent {
    //   height: 6rem;
    //   transition: all $easing;
    // }

    // &--pinned {
    //   position: fixed;
    //   top: 0;
    //   max-width: 144rem;
    //   left: 50%;
    //   transform: translateX(-50%);
    //   margin-top: 0 !important;
    //   width: 100%;
    //   padding: 2.4rem;
    //   padding-left: 30rem;
    //   z-index: 10;
    // }

    & p {
      font-size: 1.8rem;
    }

    &__wrapper {
      row-gap: 0.6rem !important;

      & a {
        font-size: 2rem;
      }
    }
  }
}

.homeHero {
  margin: 0 auto;

  &__title {
    transform: translateX(5rem);
    animation: heroTitle 0.5s ease forwards;

    & h1 {
      margin-bottom: 0;
    }
  }

  &__img {
    animation: heroImg 0.5s ease forwards;
  }
  
  &__logodot {
    top: -1.6rem;
    left: 50%;
    transform: translateX(-50%) rotate(90deg);

    @include lg-up {
      top: 50%;
      transform: translateY(-50%);
      left: -1.6rem;
    }
  }
  
  &__text {
    transform: translateX(0rem) !important;
    opacity: 0;
    animation: heroText 0.7s ease 0.5s forwards;
  }
}

@keyframes heroImg {
  0% {
    transform: translateX(30rem);
  }

  100% {
    transform: translateX(0);
  }
}

@keyframes heroTitle {
  0% {
    transform: translateX(15rem);
  }

  100% {
    transform: translateX(0);
  }
}

@keyframes heroDot {
  100% {
    background-color: $white;
  }
}

@keyframes heroText {
  0% {
    opacity: 0;
    transform: translateX(10rem);
  }
  100% {
    opacity: 1;
    transform: translateX(0);
  }
}
.kontaktcards {
    &__card {
        width: 100%;
        border: 0.5rem solid rgba($color: $secondary, $alpha: 0.2);
        transition: all $easing;
        
        @include lg-up {
            width: calc(50% - 1.2rem);
        }
        
        &:hover {
            border-color: $secondary;
        }

        &__gmaps {
            & svg {
                fill: $primary !important;
            }
        }
    }
}
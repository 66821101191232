.jobteaser {
    &__card {
        border: 0.5rem solid rgba($color: $secondary, $alpha: 0.2);
        width: 100%;
        margin-bottom: 2.4rem;
        transition: all $easing;
        
        @include md-up {
            width: calc(50% - 1.2rem);
            margin-bottom: 2.4rem;
        }
        
        @include lg-up {
            margin-bottom: 0rem;
            width: calc(33% - 1.2rem);
        }

        &:hover {
            border-color: $secondary;
        }
    }
}
.twocards {
    &__card {
        border: 0.5rem solid rgba($color: $secondary, $alpha: 0.2);
        transition: all $easing;
        width: 100%;
        
        @include lg-up {
            width: calc(50% - 1.2rem);
        }
        
        &:hover {
            border-color: $secondary;
        }
    }
}
.zitat {
    &__symbols {
        font-size: 5.4rem;     
        margin-top: -3.4rem;       
        
        @include lg-up {
            margin-top: -10rem; 
            font-size: 15.4rem;            
        }
    }

    &__logodot {
        top: -1.6rem;
        left: 50%;
        transform: translateX(-50%) rotate(90deg);
        width: 3.2rem;
    
        @include lg-up {
          top: 50%;
          transform: translateY(-50%);
          // animation: heroDot 0.5s ease 0.5s forwards;
          left: -1.6rem;
        }
      }
}
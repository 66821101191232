
// @font-face {
//   font-family: 'Gilroy';
//   src:
//     url('./fonts/gilroy-medium.woff') format('woff');
//   font-weight: 500;
//   font-style: normal;
//   font-display: swap;
// }

@font-face {
  font-family: 'Fellix-Bold';
  src:
    url('./fonts/Fellix-Bold.otf') format('opentype');
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Fellix';
  src:
    url('./fonts/Fellix.otf') format('opentype');
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}